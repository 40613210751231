@import "reset";
.source-font{
  font-family: Source Code Pro;
}
.poppins{
  font-family: 'Poppins', sans;
}

.roboto {
  font-family: 'Roboto', sans-serif;
}

@blueColor: #3535E1;
@blackColor: #000000;
@greenColor: #CCFF00;
@greyColor: #EDEDED;
@greyLightColor: #F9F9F9;

@sizeXL: 1360px;
@sizeLG: 1280px;
@sizeMD: 1100px;
@sizeMS: 820px;
@sizeXS: 370px;
body {
  font-family: 'Roboto', sans-serif;
  background: @blackColor;
  color: #FFFFFF;
}

.menu-block.show{
  right: 0;
}
.menu-block{
  position: fixed;
  z-index: 15;
  width: 100%;
  height: 100%;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  right: -100%;
  transition: right 0.4s;
  &__container{
    flex: 1;
    padding: 40px;
    display: flex;
    @media(max-width: @sizeMS){
      padding: 30px 32px;
    }
  }
  &__row{
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  &__header{
    width: 100%;
    &-wrapper{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-logo{
      @media(max-width: @sizeMS){
        img{
          max-width: 122px;
        }
      }
    }
  }
  &__body{
    &-wrapper{
      padding-top: 127px;
      @media(max-width: @sizeMS){
        padding-top: 100px;
      }
      @media(max-width: @sizeXS){
        padding-top: 40px;
      }
    }
    &-list{
      display: flex;
      flex-direction: column;
    }
    &-link{
      font-weight: 400;
      font-size: 24px;
      line-height: 20px;
      text-align: center;
      color: #FFFFFF;
      text-decoration: none;
      padding-bottom: 48px;
      @media(max-width: @sizeMS){
        font-size: 18px;
        line-height: 20px;
        padding-bottom: 40px;
      }
      @media(max-width: @sizeXS){
        font-size: 15px;
        padding-bottom: 30px;
      }
    }
    &-actions{
      display: flex;
      justify-content: center;
      padding-top: 35px;
      gap: 4px;
      @media(max-width: @sizeMS){
        padding-top: 10px;
      }
      &__btn{
        font-weight: 600;
        font-size: 18px;
        color: #000000;
        padding: 18px 85px;
        border: 1px solid @greenColor;
        border-radius: 8px;
        background: @greenColor;
        cursor: pointer;
        @media(max-width: @sizeMS){
          padding: 18px 32px;
        }
      }
    }
  }
  &__footer{
    flex: 1;
    display: flex;
    align-items: flex-end;
    width: 100%;
    &-wrapper{
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      width: 100%;
      @media(max-width: @sizeMS){
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
      }
    }
    &-copy{
      @media(max-width: @sizeMS){
        display: none;
      }
    }
    &-contact{
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      @media(max-width: @sizeMS){
        padding-bottom: 51px;
      }
      @media(max-width: @sizeXS){
        padding-bottom: 25px;
      }
      &__links{
        margin-left: auto;
        a:nth-child(1){
          margin-right: 30px;
        }
        @media(max-width: @sizeMS){
          margin-left: auto;
          margin-right: auto;
          padding-bottom: 15px;
        }
      }
      &__email{
        a{
          color: @greenColor;
          text-decoration: none;
        }
        @media(max-width: @sizeMS){
          span{
            display: none;
          }
          a{
            color: #FFFFFF;
            font-size: 14px;
            line-height: 22px;
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.header.header-fix{
  background: #FFFFFF;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
  .header-menu{
    &__link{
      color: black;
    }
  }
  .header-mobile-btn{
    svg{
      path{
        stroke: black;
      }
    }
  }
  .header-logo{
    img{
      filter: invert(1);
    }
  }
  .header-action{
    &__btn{
      background: @greenColor;
      color: black;
    }
  }
}
.header{
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  transition: background-color 0.4s;
  &-logo{
    @media(max-width: @sizeMS){
      img{
        max-width: 122px;
        height: auto;
      }
    }
  }
  &-container{
    width: 100%;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 40px;
    padding-bottom: 40px;
    @media(max-width: @sizeXL){
      max-width: 1200px;
    }
    @media(max-width: @sizeLG){
      max-width: calc(100% - 80px);
    }
    @media(max-width: @sizeMS){
      max-width: calc(100% - 60px);
      padding-top: 25px;
      padding-bottom: 25px;
    }
  }
  &-row{
    display: flex;
    align-items: center;
  }
  &-mobile-btn{
    display: none;
    @media(max-width: @sizeMD){
      display: block;
      margin-left: auto;
    }
  }
  &-menu{
    margin-right: 48px;
    margin-left: auto;
    @media(max-width: @sizeMD){
      display: none;
    }
    &__wrapper{
      display: flex;
      align-items: center;
      gap: 48px;
    }
    &__link{
      font-weight: 400;
      font-size: 18px;
      color: #FFFFFF;
      transition: color 0.4s;
      text-decoration: none;
      &:hover{
        color: @greenColor;
      }
    }
  }
  &-action{
    @media(max-width: @sizeMD){
      display: none;
    }
    &__btn{
      transition: background-color, color 0.4s;
      font-weight: 600;
      font-size: 18px;
      color: #FFFFFF;
      padding: 16px 25px;
      border: 1px solid @greenColor;
      border-radius: 8px;
      background: transparent;
      cursor: pointer;
      height: 61px;
      &:hover{
        background: @greenColor;
        color: @blackColor;
      }
    }
  }
}
.intro{
  &-image{
    width: 100%;
    img{
      width: 100%;
    }
  }
  &-container{
    width: 100%;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 40px;
    padding-bottom: 40px;
    position: sticky;
    top: 0;
    @media(max-width: @sizeXL){
      max-width: 1200px;
    }
    @media(max-width: @sizeLG){
      max-width: calc(100% - 80px);
    }
    @media(max-width: @sizeMS){
      max-width: calc(100% - 60px);
      padding-top: 32px;
      padding-bottom: 32px;
    }
    @media(max-width: @sizeXS){
      max-width: calc(100% - 40px);
    }
  }
  &-row{
    display: flex;
    flex-flow: row wrap;
  }
  &-title{
    font-weight: 600;
    font-size: 85px;
    line-height: 85px;
    text-align: center;
    padding-top: 216px;
    text-align: center;
    width: 100%;
    span{
      color: @greenColor;
    }
    color: #FFFFFF;
    @media(max-width: @sizeMD){
      font-size: 65px;
      line-height: 65px;
      padding-top: 138px;
    }
    @media(max-width: @sizeMD){
      font-size: 35px;
      line-height: 40px;
      padding-top: 106px;
    }
    @media(max-width: @sizeXS){
      font-size: 30px;
      line-height: 32px;
    }
  }
  &-tags{
    margin-left: auto;
    margin-right: auto;
    padding-top: 80px;
    @media(max-width: @sizeMD){
      padding-top: 48px;
    }
    @media(max-width: @sizeMD){
      padding-top: 40px;
    }
    &__list{
      display: flex;
      flex-flow: row wrap;
      max-width: 1035px;
      justify-content: center;
      @media(max-width: @sizeMD){
        max-width: 755px;
      }
      @media(max-width: @sizeMD){
        max-width: 100%;
      }
    }
    &__item{
      font-weight: 400;
      font-size: 20px;
      color: #FFFFFF;
      border: 1px solid #424242;
      border-radius: 6px;
      padding: 11px 14px;
      display: flex;
      align-items: center;
      transition: background-color, border, color, 0.4s;
      cursor: pointer;
      margin-left: 5px;
      margin-right: 5px;
      margin-bottom: 10px;
      background: #000c;
      &:nth-child(1){
        margin-left: 0;
      }
      &:nth-last-child(1){
        margin-right: 0;
      }
      @media(max-width: @sizeMD){
        margin-left: 3.5px;
        margin-right: 3.5px;
        margin-bottom: 7px;
      }
      @media(max-width: @sizeXS){
        margin-left: 2.5px;
        margin-right: 2.5px;
        margin-bottom: 5px;
      }
      @media(max-width: @sizeMD){
        font-size: 14px;
        padding: 8px 10px;
      }
      @media(max-width: @sizeMD){
        font-size: 14px;
        padding: 4px 10px;
        img{
          width: 9px;
          height: auto;
        }
      }
      @media(max-width: @sizeXS){
        font-size: 10px;
        padding: 4px 8px;
      }
      &:hover{
        background: @greenColor;
        color: @blackColor;
        border-color: @greenColor;
      }
    }
  }
}
.information.blue{
  background: @blueColor;
  .information-title{
    color: #FFFFFF;
  }
}
.information.white{
  background: #FFFFFF;
}
.information{
  padding-top: 120px;
  padding-bottom: 120px;
  background: #F5F5F5;
  @media(max-width: @sizeMD){
    padding-top: 48px;
    padding-bottom: 48px;
  }
  &-container{
    width: 100%;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    @media(max-width: @sizeXL){
      max-width: 1200px;
    }
    @media(max-width: @sizeLG){
      max-width: calc(100% - 80px);
    }
    @media(max-width: @sizeLG){
      max-width: calc(100% - 44px);
    }
    @media(max-width: @sizeXS){
      max-width: calc(100% - 32px);
    }
  }
  &-row{
    display: flex;
    flex-flow: row wrap;
  }
  &-title{
    font-weight: 600;
    font-size: 85px;
    line-height: 85px;
    text-align: center;
    color: #000000;
    width: 100%;
    padding-bottom: 64px;
    @media(max-width: @sizeMD){
      font-size: 50px;
      line-height: 55px;
      padding-bottom: 32px;
    }
    @media(max-width: @sizeMS){
      font-size: 38px;
      line-height: 42px;
      padding-bottom: 32px;
    }
    @media(max-width: @sizeXS){
      font-size: 30px;
      line-height: 32px;
    }
  }
  &-content{
    width: 100%;
    &__list{
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 36px;
      padding-bottom: 36px;
      @media(max-width: @sizeMS){
        grid-template-columns: 1fr;
        gap: 16px;
        padding-bottom: 17px;
      }
    }
    &__list-small{
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 36px;
      padding-bottom: 36px;
      @media(max-width: @sizeMD){
        display: flex;
        flex-flow: row wrap;
        .information-content__item{
          &:nth-child(1),
          &:nth-child(2){
            width: 48%;
          }
          &:nth-child(3){
            width: 100%;
          }
        }
      }
      @media(max-width: @sizeMS){
        display: grid;
        grid-template-columns: 1fr;
        gap: 16px;
        padding-bottom: 17px;
        .information-content__item{
          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3){
            width: 100%;
          }
        }
      }
    }
    &__item{
      background: #FFFFFF;
      border-radius: 12px;
      &-wrapper{
        padding: 40px;
        display: flex;
        flex-direction: column;
        @media(max-width: @sizeMD){
          padding: 32px;
        }
        @media(max-width: @sizeMS){
          padding: 24px;
        }
      }
      &-icon{
        width: 65px;
        height: 65px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        background: @greenColor;
        margin-bottom: 24px;
        img{
          position: absolute;
        }
        @media(max-width: @sizeMD){
          width: 48px;
          height: 48px;
          margin-bottom: 16px;
        }
      }
      &-title{
        font-weight: 700;
        font-size: 30px;
        line-height: 38px;
        color: #000000;
        max-width: 452px;
        @media(max-width: @sizeMD){
          font-size: 18px;
          line-height: 23px;
        }
      }
      &-desc{
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        color: #000000;
        padding-top: 16px;
        @media(max-width: @sizeMD){
          font-size: 14px;
          line-height: 22px;
        }
        li{
          position: relative;
          display: flex;
          align-items: center;
          padding-left: 18px;
          &:before{
            width: 4px;
            height: 4px;
            background: #000000;
            display: block;
            content: '';
            border-radius: 100%;
            margin-right: 16px;
            position: absolute;
            left: 0;
            top: 13px;
            @media(max-width: @sizeMS){
              top: 9px;
            }
          }
        }
      }
    }
    &__logo{
      width: 100%;
      &-list{
        display: flex;
        align-items: center;
        flex-flow: row wrap;
        justify-content: center;
      }
      &-item{
        background: #F5F5F5;
        border-radius: 12px;
        width: 166px;
        height: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 9.5px;
        margin-right: 9.8px;
        margin-bottom: 19px;
        &:nth-last-child(1){
          margin-right: 0;
        }
        &:nth-child(1){
          margin-left: 0;
        }
        @media(max-width: @sizeMD){
          margin-left: 5px;
          margin-right: 5px;
          margin-bottom: 10px;
        }
        @media(max-width: @sizeMD){
          width: 24%;
        }
        @media(max-width: @sizeMS){
          width: 30%;
          img{
            max-width: 80%;
          }
        }
        @media(max-width: @sizeXS){
          width: 48%;
        }
      }
    }
  }
}

.footer{
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
  &-container{
    width: 100%;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    @media(max-width: @sizeXL){
      max-width: 1200px;
    }
    @media(max-width: @sizeLG){
      max-width: calc(100% - 80px);
    }
    @media(max-width: @sizeLG){
      max-width: calc(100% - 44px);
    }
    @media(max-width: @sizeXS){
      max-width: calc(100% - 32px);
    }
  }
  &-row{
    display: flex;
    flex-flow: row wrap;
    align-items: flex-end;
    padding-top: 213px;
    padding-bottom: 80px;
    @media(max-width: @sizeMD){
      flex-direction: column-reverse;
      align-items: center;
      padding-top: 48px;
      padding-bottom: 48px;
    }
  }
  &-contact{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-left: auto;
    @media(max-width: @sizeMD){
      margin-left: auto;
      margin-right: auto;
    }
    @media(max-width: @sizeMS){
      padding-bottom: 15px;
    }
    &__links{
      margin-left: auto;
      padding-bottom: 17px;
      a:nth-child(1){
        margin-right: 30px;
      }
      @media(max-width: @sizeMD){
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 15px;
      }
    }
    &__email{
      a{
        color: @greenColor;
        text-decoration: none;
      }
    }
  }

}